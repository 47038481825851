import { Deserializable } from "./deserializable.interface";
import Model from "./model";
import Product from "./product";

class Order extends Model implements Deserializable<Order> {
    id: number = 0;
    code: string = "";
    created_at: string = "";
    total: number = 0;
    total_for_ctv: number = 0;
    status!: string;
    product_names: string = "";
    items: Product[] = [];
    shipping: { fee_amount: number, cod_partner: string, address: string, order_code: string, receiver_name: string, receiver_phone: string } = {
        fee_amount: 0,
        cod_partner: "",
        address: "",
        order_code: "",
        receiver_name: "",
        receiver_phone: ""
    };
  fee : number = 0;
    payment: string = "";
    note: string = "";
    discount: number = 0;
    compare_status!: number;
    is_able_to_cancel: boolean = false;
    payment_method: string = '';
    paid: number = 0;

    fromJson(input: any): Order {
        super.fromJson(input);
        if (typeof input.items !== 'undefined') {
            this.items = input.items.map((item: any) => {
                return new Product().fromJson(item);
            })
        }
        this.compare_status = parseInt(input.compare_status);

        return this;
    }

    getTotalFormated() {
        return this.total.toLocaleString() + ' đ';
    }

    getTotalForCtvFormated() {
        return this.total_for_ctv.toLocaleString() + ' đ';
    }

    getShippingFeeFormated() {
        return this.shipping.fee_amount.toLocaleString() + ' đ';
    }

    getDiscountFormated() {
        return this.discount.toLocaleString() + ' đ';
    }

    getOrderItems() {
        return this.items;
    }

    getShippingAddress() {
        return this.shipping.address;
    }

    getCodPartner() {
        return this.shipping.cod_partner;
    }

    getCodStatus() {

        let text = 'Chưa đối soát';
        if (this.compare_status == 1) {
            text = 'Đã đối soát';
        } else if (this.compare_status == 2) {
            text = 'Không vận chuyển';
        }

        return text;
    }
    ableToCancel()
    {
        return this.is_able_to_cancel;
    }

    ableToRepay()
    {
        return this.paid == 0
            && this.payment_method == '3';
    }

    getTotal()
    {
        return this.total + this.shipping.fee_amount - this.discount;
    }
}

export default Order;
