import { Deserializable } from "./deserializable.interface";
import Model from "./model";
import Product from "./product";

export default class Serial extends Model implements Deserializable<Serial> {
    product!: Product;
    serial_number!: string;
    status!: string;
    activated_at!: string;
    expired_at!: string;
    customer_name!: string;
    customer_phone!: string;
    customer_province!: string;
    activate_to_earn: boolean = false;

    fromJson(input: {}) : Serial {
        super.fromJson(input);

        return this;
    }
}