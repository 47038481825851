import Page from "../models/page";
import Api from "./api";

export default class PageService extends Api{
    protected prefix = 'pages';

    get(slug: string): Promise<Page>
    {
        return super.get(this.prefix + '/' + slug);
    }
}