import { Deserializable } from "./deserializable.interface";
import Model from "./model";

export default class Address extends Model implements Deserializable<Address> {
    id!: string;
    name!: string;
    phone!: string;
    province!: string;
    district!: string;
    ward!: string;
    address!: string;
    default: boolean = false;
    full_address!: string;

    fromJson(input: {}) : Address {
        super.fromJson(input);

        return this;
    }

    addressFormatted()
    {
        return this.full_address;
    }
}