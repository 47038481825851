export default class Request {
	fieldOperatorMapping: any = {
		card_number: 'like',
		seri: 'like',
		created_from: 'gte',
		created_to: 'lte',
		desc: 'like'
	};
	datePickerField = [
		'created_from',
		'created_to'
	];
	fieldMapping: any = {
		'created_from': 'created_at',
		'created_to': 'created_at'
	}
	params: any;
	filter: any[] = [];
	order: any[] = [];
	page: number = 1;
	perpage: number = 12;
	result: any;

	constructor(params: any) {
		this.params = params;
	}

	serialize(obj: any, prefix: string): string {
		var str = [],
			p;
		for (p in obj) {
			if (obj.hasOwnProperty(p)) {
				var k = prefix ? prefix + "[" + p + "]" : p,
					v = obj[p];
				str.push((v !== null && typeof v === "object") ?
					this.serialize(v, k) :
					encodeURIComponent(k) + "=" + encodeURIComponent(v));
			}
		}

		return str.join("&");
	}

	httpBuildQuery() {
		this.result = {};
		this.prepareFilter();
		this.prepareOrderFilter();
		this.preparePage();
		this.prepareExtraParams();
		this.prepareUserId();
		this.prepareRequestWarrantyParam();
		this.prepareCategory();
		console.log(this.result);
		// this.prepareDatePickerField();
		
		return this.serialize(this.result, '');
	}

	protected isset(key: any) {
		return typeof this.params[key] !== 'undefined'
	}

	protected preparePage() {
		if (this.isset('page')) {
			this.page = this.params['page'];
		}
		if (this.isset('perpage')) {
			this.perpage = this.params['perpage'];
		}

		this.result['page'] = this.page;
		this.result['perpage'] = this.perpage;
	}

	protected prepareExtraParams() {
		if (this.isset('q')) {
			this.result['q'] = this.params['q'];
		}
		if (this.isset('lock_commission')) {
			this.result['lock_commission'] = this.params['lock_commission'];
		}
	}

	protected prepareUserId() {
		if (this.isset('user_id')) {
			this.result['user_id'] = this.params['user_id'];
		}
	}

	protected prepareRequestWarrantyParam() {
		if (this.isset('warranty_param')) {
			this.result['warranty_param'] = this.params['warranty_param'];
		}
	}

	protected prepareFilter() {
		let filters: any = [];
		if (this.isset('filter')) {
			Object.keys(this.params.filter).map(field => {
				if (this.params.filter[field]) {
					let filter: any = {
						field: this.fieldMapping.hasOwnProperty(field) ? this.fieldMapping[field] : field,
						value: this.datePickerField.indexOf(field) !== -1
							? this.prepareDatePickerField(this.params.filter[field])
							: this.params.filter[field],
					};
					if (this.params.filter[field] instanceof Array && typeof this.fieldOperatorMapping[field] === 'undefined') {
						filter['operation'] = 'in';
					}
					if (typeof this.fieldOperatorMapping[field] !== 'undefined') {
						filter['operation'] = this.fieldOperatorMapping[field];
					}
					filters.push(filter);
				}
			});
		}

		if (filters.length > 0) {
			this.result['filter'] = filters;
		}
	}

	protected prepareOrderFilter() {
		if (this.isset('order_code')) {
			this.result['order_code'] = this.params['order_code'];
		}
		if (this.isset('cod_code')) {
			this.result['cod_code'] = this.params['cod_code'];
		}
		if (this.isset('phone')) {
			this.result['phone'] = this.params['phone'];
		}
		if (this.isset('name')) {
			this.result['name'] = this.params['name'];
		}
		if (this.isset('address')) {
			this.result['address'] = this.params['address'];
		}
		if (this.isset('status')) {
			this.result['status'] = this.params['status'];
		}
		if (this.isset('cod_compare_status') && this.params['cod_compare_status'] !== "") {
			this.result['cod_compare_status'] = this.params['cod_compare_status'];
		}
		if (this.isset('type')) {
			this.result['type'] = this.params['type'];
		}
		if (this.isset('location')) {
			this.result['location'] = this.params['location'];
		}
		if (this.isset('partnerStore')) {
			this.result['partnerStore'] = this.params['partnerStore'];
		}
		if (this.isset('payment_method')) {
			this.result['payment_method'] = this.params['payment_method'];
		}
		if (this.isset('p_ids')) {
			this.result['p_ids'] = this.params['p_ids'];
		}
		if (this.isset('seri')) {
			this.result['seri'] = this.params['seri'];
		}
		if (this.isset('exclude_seris')) {
			this.result['exclude_seris'] = this.params['exclude_seris'];
		}
		if (this.isset('customer_id')) {
			this.result['customer_id'] = this.params['customer_id'];
		}
		if (this.isset('code')) {
			this.result['code'] = this.params['code'];
		}
		if (this.isset('analysic')) {
			this.result['analysic'] = this.params['analysic'];
		}

		if (this.isset('revenue_from')) {
			this.result['revenue_from'] = this.prepareDatePickerField(this.params['revenue_from']);
		}
		if (this.isset('revenue_to')) {
			this.result['revenue_to'] = this.prepareDatePickerField(this.params['revenue_to']);
		}
	}

	protected prepareDatePickerField(value: string) {
		var date = new Date(value);
		let result: any[] = [];
		result.push(date.getFullYear());
		var month = date.getMonth() + 1;
		result.push(month < 10 ? '0' + month.toString() : month);
		result.push(date.getDate());
		
		return result.join("-");
	}

	protected prepareCategory() {
		if (this.isset('category')) {
			this.result['category'] = this.params['category'];
		}
	}
}