import React from "react";
import jwtDecode from "jwt-decode";
import Auth from "../services/auth";
import User from "../models/user";

const authService = new Auth();

export const login = async (
  credentials: { username: any; password: any },
  dispatch: React.Dispatch<any>
) => {
  dispatch({ type: "REQUEST_LOGIN" });
  try {
    const response = await authService.login(credentials);
    localStorage.setItem("defaultDelivery", response.user.defaultDelivery);
    localStorage.setItem("token", JSON.stringify(response.token));
    dispatch({
      type: "LOGIN_SUCCESS",
      payload: {
        user: new User().fromJson(response.user),
        token: response.token,
      },
    });
    window.location.href = "/";
  } catch (error) {
    const response = await error;
    let errors: string[] = [];
    Object.values(response).map((message: any) => {
      errors.push(message);
    });
    dispatch({ type: "LOGIN_ERROR", payload: { messages: errors.join("\n") } });
  }
};

export const logout = async (dispatch: React.Dispatch<any>) => {
  const token = getToken();
  try {
    await authService.logout(token);
    dispatch({ type: "LOGOUT" });
    localStorage.removeItem("token");
    window.location.reload();
  } catch (error) {
    console.log(error);
  }
};

export const getToken = () => {
  const token = localStorage.getItem("token");
  return token ? JSON.parse(token) : "";
};

export const getUser = () => {
  const user = localStorage.getItem("user");
  return user ? JSON.parse(user) : {};
};

export const isValidToken = () => {
  const token = getToken();
  return (
    token &&
    jwtDecode<{ name: string; exp: number }>(token).exp > Date.now() / 1000
  );
};

export const currentUser = async (dispatch: React.Dispatch<any>) => {
  const token = getToken();
  try {
    const response = await authService.getCurrentUser(token);
    localStorage.setItem("token", JSON.stringify(response.token));
    dispatch({
      type: "CURRENT_CUSTOMER",
      payload: {
        user: new User().fromJson(response.user),
      },
    });
  } catch (error) {
    dispatch({ type: "LOGOUT" });
    localStorage.removeItem("token");
  }
};
