import Api from "./api";

// /brands/fromFe
export default class BrandsService extends Api {
    protected prefix = 'brands/fromFe';
    getBrands(): Promise<any> {
        return this.get(this.prefix);
    }
  getProductFormOrder(
    token: string,
    params: {} = {
      page: 1,
     perpage:10000
    },
    page: number = 1,
    perpage: number = 1000
  ) {
    const url = `transfer-orders/get-product-from-order`;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return this.get(url, params, headers);
  }
  getSeriFormOrder(
    token: string,
    params: {} = {
      page: 1,
      perpage: 100,
     seri :''

    },
  ) {
    const url = `transfer-orders/get-transfer-order-seri`;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return this.get(url, params, headers);
  }
   getPriceOrderPrice(
    token: string,
    params: {} = {
    },
    cusotmerId: number = 1,
    productId: number = 1000
  ) {
    const url = `transfer-orders/get-transfer-order-price/${cusotmerId}/${productId}`;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return this.get(url, params, headers);
  }
}
