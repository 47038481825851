import React from 'react';
import defaultImage from "./../../assets/images/placeholder.png";

class Props {
    class: string = "";
    src: string = "";
}

export default class Image extends React.Component <Props, {}>{
    static defaultProps = {
        class: '',
        src: '',
    };

    render() 
    {
        return (
            <>
            {
                this.props.src
                ? <object data={this.props.src} className={this.props.class} type="image/png">
                    <img src={defaultImage} ></img>
                </object>
                : <img className={this.props.class}  src={defaultImage} ></img>
            }
            </>
        );
    }
}

Image.defaultProps = {
    class: '',
    src: '',
}