import { Deserializable } from "./deserializable.interface";
import Model from "./model";
import Product from "./product";

class TransferOrder extends Model implements Deserializable<TransferOrder> {
    id: number = 0;
    code: string = "";
    customer_name: string = '';
    transfered_at: string = "";
    number_of_seris: number = 0;
    amount: number = 0;
    items: {}[] = [];

    fromJson(input: any): TransferOrder {
        super.fromJson(input);
        // if (typeof input.items !== 'undefined') {
        //     this.items = input.items.map((item: any) => {
        //         return new Product().fromJson(item);
        //     })
        // }
        
        return this;
    }
}

export default TransferOrder;