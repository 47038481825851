import React from 'react';
import User from '../../user/user';
import "./header.scss";
import Topbar from "./topbar";
import MainMenu from "./../../menus/main-menu";
import MobileMainMenu from "./../../menus/mobile-main-menu";
import { Link } from 'react-router-dom';
import { BrowserView, MobileView } from 'react-device-detect';
import CategoryService from '../../../services/category';
import Category from '../../../models/category';
import { getToken } from "../../../actions/AuthAction";
import BrandsService from '../../../services/brands';

class HeaderProps {
    logo: string = '';
    hotline: string = 'CSKH : ' + process.env.REACT_APP_HOTLINE;
}

class HeaderState {
  categories: Category[] = []
   brands : any =  {}
}

export default class Header extends React.Component<HeaderProps, HeaderState>{
    constructor(props: any) {
        super(props);
        this.state = {
          categories: [],
          brands : {}
        };
    }
    async fetchCategories() {
        const token = getToken();
        const response = await new CategoryService().getCategories(token);
        const cates = new Category().listCategories(response);
        this.setState({
            categories: cates
        });
    }
  async fetchBrands() {
    const response = await new BrandsService().getBrands()
    this.setState({
      brands : response?.items[0]
    })
    console.log(response?.items[0]);

  }
    componentDidMount() {
      this.fetchCategories();
      this.fetchBrands();
    }

    render() {
        return (
            <div className="header-wapper">
                <Topbar left={ 'CSKH : ' + this?.state?.brands?.customer_care_phone} right={
                    <>
                        <BrowserView>
                            <ul className="top-menu nav justify-content-end">
                                <li className="nav-item">
                                    <Link to="/s/tai-tai-lieu" className="text-white">Download</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/kick-hoat-bao-hanh" className="text-white">Tra cứu bảo hành</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/lich-su-bao-hanh" className="text-white">Lịch sử bảo hành</Link>
                                </li>
                                <li className="nav-item">
                                    <User />
                                </li>
                            </ul>
                        </BrowserView>
                        <MobileView>
                            <ul className="top-menu nav justify-content-end">
                                <li className="nav-item">
                                    <Link to="/s/tai-tai-lieu" className="text-white">Download</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/kick-hoat-bao-hanh" className="text-white">Tra cứu bảo hành</Link>
                                </li>
                            </ul>
                        </MobileView>
                    </>
                } />
                <div className="header container">
                    <BrowserView>
                        <div className="row" >
                            <div className="logo col-12 col-sm-2 text-center">
                                <a href="/"><img style={{width:'60px'}} src={this.props.logo} alt="Logo" /></a>
                            </div>
                            <div className="col-12 col-sm-10">
                                <MainMenu categories={this.state.categories} />
                            </div>
                        </div>
                    </BrowserView>
                    <MobileView>
                        <MobileMainMenu logo={this.props.logo} categories={this.state.categories} />
                    </MobileView>
                </div>
            </div>
        );
    }
}
