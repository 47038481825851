import { Deserializable } from "./deserializable.interface";
import Model from "./model";

export default class Audit extends Model implements Deserializable<Audit> {
    id!: number;
    order_id!: string;
    trans_id!: string;
    order_code!: string;
    trans_note!: string;
    amount!: number;
    balance!: number;
    created_at!: string;

    fromJson(input: any) {
        super.fromJson(input);

        return this;
    }
}