import React, { useReducer, useContext, useMemo, createContext } from 'react';
import { initialState, AuthReducer, State } from '../reducers/AuthReducer';

const AuthContext = createContext<{
    authState: State;
    dispatch: React.Dispatch<any>;
}>({
    authState: initialState,
    dispatch: () => null
});

const useAuthContext = () => {
    return useContext(AuthContext);
}

const AuthProvider: React.FC = ({ children }) => {
    const [authState, dispatch] = useReducer(AuthReducer, initialState);

    const contextValue = useMemo(() => {
        return { authState, dispatch };
    }, [authState, dispatch]);

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
}

export { useAuthContext, AuthProvider }