import React from 'react';

export default class Copyright extends React.Component <any, any>{
    constructor(props: any) {
        super(props);
        this.state = {
          dataSetup: {},
        };
      }
   
    render() {
        console.log(this.props,'props');
        return (
            <div className="row border-top pt-1">
                <div className="col-12 text-center">
                    {this?.props?.dataSetup?.copy_write}
                    {/* ©2021 - Bản quyền của Công Ty Cổ Phần Công Nghệ FQS Việt Nam */}
                </div>
            </div>
        );
    }
}