import React, { Suspense } from "react";
import { Route } from "react-router-dom";

interface Props {
    component: React.ComponentType<any>;
    routes: any;
}

const PublicRoute: React.FC<Props> = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props => (
                <Suspense fallback={<></>}>
                    <Component {...props} />
                </Suspense>
            )}
        />
    );
}

export default PublicRoute;