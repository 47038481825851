import React from "react";
import { Link } from "react-router-dom";
import { useCartContext } from "../../contexts/CartContext";
import { getTotalItemsInCart } from "../../actions/CartAction";
import "./cart.scss";

const Cart: React.FC = () => {
    const { cartState } = useCartContext();

    return (
        <div className="cart-wrapper">
            <div className="cart-inner">
                <Link className="cart" to="/gio-hang">
                    <i className="bi bi-bag-fill"></i>
                </Link>
                <span className="cart-basket text-center">
                    {getTotalItemsInCart(cartState.items)}
                </span>
            </div>
        </div>
    )
}

export default Cart;