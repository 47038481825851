import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router";

interface Props {
    quote?: string;
    title?: string;
    imageUrl?: string;
    description?: string;
    hashtag?: string;
}

const HelmetData: React.FC<Props> = (props) => {
    const location = useLocation();
    const currentUrl = `${process.env.REACT_APP_URL}/${location.pathname}`;
    const quote = typeof props.quote !== "undefined" ? props.quote : "";
    const title = typeof props.title !== "undefined" ? props.title : "";
    const image = typeof props.imageUrl !== "undefined" ? props.imageUrl : "";
    const description = typeof props.description !== "undefined" ? props.description : "Điện tử 2C";
    const hashtag = typeof props.hashtag !== "undefined" ? props.hashtag : "";

    return (
        <Helmet>
            <meta name="theme-color" content="#fff" />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={title} />
            <meta property="og:quote" content={quote} />
            <meta property="og:hashtag" content={hashtag} />
            <meta property="og:image" content={image} />
            <meta content="image/*" property="og:image:type" />
            <meta property="og:url" content={currentUrl} />
            <meta property="og:site_name" content="Điện tử 2C" />
            <meta property="og:description" content={description} />
        </Helmet>
    );
}

export default HelmetData;
