import React from 'react';
import Button from "./../commons/button";
import Image from "./../commons/image";

import ProductService from "./../../services/product";
import "./auto-complete-search.scss";
import { ProductPagination } from '../../models/pagination';
import Product from '../../models/product';
import { Link } from "react-router-dom";

export default class AutoCompleteSearch extends React.Component <any, any>{
    static defaultProps = {
        'text': '',
        'showButton': false,
    };
    protected productService: ProductService = new ProductService;
    protected keyword: string = '';
    protected wrapperRef: any;
    constructor(props: any) 
    {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.showResult = this.showResult.bind(this);
        this.hideResult = this.hideResult.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.wrapperRef = React.createRef();
        this.state = {
            isLoading: false,
            showResult: false,
            products: new ProductPagination().fromJson({hasMore: false, items: []}),
        };
    }

    handleClickOutside(event: any) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.setState({
                showResult: false
            })
        }
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }


    onSubmit(event: any)
    {
        event.preventDefault();
        this.loadProducts();
    }

    onChange(event: any)
    {
        this.keyword = event.target.value;
        this.loadProducts();
    }

    showResult(event:any)
    {
        if (this.state.products.items.length > 0) {
            this.setState({
                showResult: true
            });
        }
    }

    hideResult(event: any)
    {
        this.setState({
            showResult: false
        });
    }

    loadProducts()
    {
        this.setState({
            isLoading: true,
        })
        this.productService.getProducts({q: this.keyword})
            .then((response: ProductPagination) => {
                const result = new ProductPagination().fromJson(response) 
                this.setState({
                    isLoading: false,
                    products: result,
                    showResult: result.items.length > 0
                })
            }).catch(error => {
                this.setState({
                    isLoading: false,
                    products: new ProductPagination().fromJson({hasMore: false, items: []}),
                    showResult: false
                })
            });
    }

    render() {
        const result = <div className="searching-result list-group">
            {this.state.products.items.map((product: Product) => {
                return <Link to={`/san-pham/` + product.sku} onClick={this.hideResult}>
                    <div className="card border-0 w-100 mt-1">
                        <div className="row no-gutters">
                            <div className="col-auto">
                                <Image src={product.featured_image} class="img-fluid"></Image>
                            </div>
                            <div className="col">
                                <div className="card-block px-2">
                                    <strong className="card-title">{product.name}</strong>
                                    <p className="card-text">{product.sku}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
            })}
            
        </div>;

        return (
            <div ref={this.wrapperRef} className="auto-complete-search">
                <form onSubmit={this.onSubmit}>
                    <div className="input-group">
                        <input type="text" name="q" placeholder={this.props.text} autoComplete="off" className="form-control auto-complete-search-input" onChange={this.onChange} onFocus={this.showResult}/>
                        {
                            this.props.showButton
                            ? <div className="input-group-append">
                                <Button isLoading={this.state.isLoading} class="pull-right btn btn-sm btn-primary">Tìm kiếm</Button>
                            </div>
                            : ''
                        }
                    </div>
                    {
                        this.state.showResult
                        ? result
                        : <></>
                    }
                </form>
            </div>
        );
    }
}
AutoCompleteSearch.defaultProps = {
    'text': "",
    'showButton': false
}