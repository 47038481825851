import React from "react";
import { Link } from "react-router-dom";

interface Props {
  first: any;
  second: any;
  third: any;
  dataSetup: any;
}

export default class Footer extends React.Component<Props, {}> {
  render() {
    console.log(this.props.dataSetup,'dataSetup')
    return (
      <div className="footer-three-columns container mb-3">
        <p className="mb-4">
          <strong>{this?.props?.dataSetup?.brand_name}</strong>
        </p>
        <div className="row">
          <div className="col-12 col-sm-3">
            <div className="contact-info">
              <p className="mb-4">
                <strong>Trụ sở chính</strong>
              </p>
              <p className="small">
                <i className="bi bi-building mr-2"></i>
                {this?.props?.dataSetup?.address}
              </p>
              {/* <p className="small">
                <i className="bi bi-building mr-2"></i>S2.08 Vinhomes Ocean
                Park, Đa Tốn, Gia Lâm, Hà Nội
              </p> */}
              <p>
                <i className="bi bi-telephone mr-2"></i>   {this?.props?.dataSetup?.contact_phone}
              </p>
            </div>
          </div>
          <div className="col-12 col-sm-3">{this.props.second}</div>
          {/* <div className="col-12 col-sm-3">
                        <div className="contact-info">
                            <p className="mb-4"><strong>Chi nhánh TP HCM</strong></p>
                            <p className="small"><i className="bi bi-building mr-2"></i>103 Phan Anh, phường Bình Trị Đông, quận Bình Tân, TP Hồ Chí Minh</p>
                            <p><i className="bi bi-telephone mr-2"></i>083 633 6688</p>
                        </div>
                    </div>
                    <div className="col-12 col-sm-3">
                        <div className="contact-info">
                            <p className="mb-4"><strong>Chi nhánh Đà Nẵng</strong></p>
                            <p className="small"><i className="bi bi-building mr-2"></i>CS1: 145 B Cách Mạng Tháng 8, phường Khuê Trung, quận Cẩm Lệ, TP Đà Nẵng</p>
                            <p><i className="bi bi-telephone mr-2"></i>07 06 272727</p>
                            <p className="small"><i className="bi bi-building mr-2"></i>CS2: 8 Trần Ngọc Sương, phường Hoà Thọ Đông, quận Cẩm Lệ, TP Đà Nẵng</p>
                            <p><i className="bi bi-telephone mr-2"></i>0934 953 359</p>
                        </div>
                    </div>
                    <div className="col-12 col-sm-3">
                        <div className="contact-info">
                            <p className="mb-4"><strong>Chi nhánh Hưng Yên</strong></p>
                            <p className="small"><i className="bi bi-building mr-2"></i>Thôn Tổ Hoả, xã Lý Thường Kiệt, huyện Yên Mỹ, tỉnh Hưng Yên</p>
                            <p><i className="bi bi-telephone mr-2"></i>0338 999 999</p>
                        </div>
                    </div> */}
          <div className="col-12 col-sm-3">
            <div className="contact-info">
              <p className="mb-4">
                <strong>HƯỚNG DẪN</strong>
              </p>
              <div>
                <Link className="text-dark" to="/s/huong-dan-dat-hang">
                  Hướng dẫn đặt hàng
                </Link>
                <br />
                <Link className="text-dark" to="/kick-hoat-bao-hanh">
                  Tra cứu thông tin bảo hành
                </Link>
                <br />
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-3">
            <div className="contact-info">
              <p className="mb-4">
                <strong>Chính sách</strong>
              </p>
              <div>
                <Link className="text-dark" to="/s/chinh-sach-doi-tra">
                  Chính sách đổi trả
                </Link>
                <br />
                <Link className="text-dark" to="/s/chinh-sach-dai-ly">
                  Chính sách đại lý
                </Link>
                <br />
                <Link className="text-dark" to="/s/chinh-sach-bao-mat">
                  Chính sách bảo mật
                </Link>
                <br />
                <Link className="text-dark" to="/s/dieu-khoan-su-dung">
                  Điều khoản sử dụng
                </Link>
                <br />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-3">{this.props.third}</div>
        </div>
      </div>
    );
  }
}
