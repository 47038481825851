import React from 'react';
import { Link } from "react-router-dom";
import Category from '../../models/category';
import Cart from '../cart/cart';
import AutoCompleteSearch from '../searches/auto-complete-search';
import "./main-menu.scss";

export default class MainMenu extends React.Component<any, any>{
    constructor(props: any) {
        super(props);
        this.state = {
            showSearchBox: false,
        };
        this.toogleSearchBox = this.toogleSearchBox.bind(this);
    }

    toogleSearchBox(event: any) {
        this.setState({
            showSearchBox: !this.state.showSearchBox
        })
    }

    render() {
        return (
            <nav className="navbar navbar-expand-lg navbar-light main-menu">
                <div className="container-fluid">
                    <div className="collapse navbar-collapse">
                        <ul className={`navbar-nav ml-auto ` + (this.state.showSearchBox ? "search-box-activated" : '')}>
                            <li className="nav-item transition">
                                <Link to="/" className="nav-link">TRANG CHỦ</Link>
                            </li>
                            <li className="nav-item transition">
                                <Link to="/s/gioi-thieu" className="nav-link">GIỚI THIỆU</Link>
                            </li>
                            <li className="nav-item transition dropdown">
                                <Link to="/san-pham/" className="nav-link dropdown-toggle">SẢN PHẨM</Link>
                                <div className="dropdown-menu" style={{ top: "90%" }}>
                                    {
                                        this.props.categories.length > 0 && this.props.categories.map((item: Category) => {
                                            return <Link
                                                to={{
                                                    pathname: `/danh-muc/${item.slug}`,
                                                    state: { name: item.name, slug: item.slug }
                                                }}
                                                className="dropdown-item"
                                            >
                                                {item.name}
                                            </Link>;
                                        })
                                    }
                                </div>
                            </li>
                            <li className="nav-item transition">
                                <Link to="/tin-tuc/" className="nav-link">TIN TỨC</Link>
                            </li>
                            <li className="nav-item transition">
                                <Link to="/s/khuyen-mai/" className="nav-link">KHUYẾN MẠI</Link>
                            </li>
                            <li className="nav-item">
                                <div className="row">
                                    <div className="col pr-0">
                                        <AutoCompleteSearch text="Tên sản phẩm, Mã Seri,..." />
                                    </div>
                                    <div className="col-auto pl-0">
                                        <button className="btn" onClick={this.toogleSearchBox}>
                                            <i className="bi bi-search"></i>
                                        </button>
                                    </div>
                                </div>
                            </li>
                            <li className="nav-item" style={{ marginLeft: "10px" }}>
                                <Cart />
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        );
    }
}