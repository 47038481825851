import React, { useMemo, useEffect, useReducer, useContext, createContext } from "react";
import { getLocalData } from "../actions/CartAction";
import { CartReducer, CartState, initialState } from "../reducers/CartReducer";

interface ContextType {
    cartState: CartState
    dispatch: React.Dispatch<{ type: string; value: unknown }>;
};

const CartContext = createContext<ContextType | any>(initialState);


const useCartContext = () => {
    return useContext(CartContext);
}

const CartProivder: React.FC = ({ children }) => {
    const [cartState, dispatch] = useReducer(CartReducer, initialState, () => {
        return { ...initialState, items: getLocalData() };
    });

    const contextValue = useMemo(() => {
        return { cartState, dispatch };
    }, [cartState, dispatch]);

    useEffect(() => {
        localStorage.setItem("cart", JSON.stringify(cartState.items));
    }, [cartState])

    return (
        <CartContext.Provider value={contextValue}>
            { children}
        </CartContext.Provider>
    );
}

export { useCartContext, CartProivder };