import { Deserializable } from "./deserializable.interface";
import Model from "./model";

export default class Attribute extends Model implements Deserializable<Attribute> {
    id!: string;
    text!: string;
    values!: AttributeValue[];

    fromJson(input: any): Attribute {
        super.fromJson(input);
        
        if (typeof input.values !== "undefined" && input.values.length > 0) {
            this.values = input.values.map((value: any) => {
                return new AttributeValue().fromJson(value);
            })
        }

        return this;
    }
}

class AttributeValue extends Model implements Deserializable<AttributeValue> {
    id!: string;
    value!: string;
    selected: boolean = false;
    disabled: boolean = true;

    fromJson(input: any): AttributeValue {
        super.fromJson(input);

        return this;
    }
}