import React from 'react';
import { Link } from "react-router-dom";
import User from "../user/user";
import AutoCompleteSearch from '../searches/auto-complete-search';
import "./main-menu.scss";
import Cart from '../cart/cart';

export default class MobileMainMenu extends React.Component<any, any>{
    constructor(props: any) {
        super(props);
        this.state = {
            dropdown: 0,
        };
        this.toggleDropdown = this.toggleDropdown.bind(this);

    }

    toggleDropdown(event: any) {
        this.setState((prevState: any) => ({
            dropdown: !prevState.dropdown
        }));
    }

    render() {
        const search = <AutoCompleteSearch showButton="true" />;
        const menu = <ul className="nav flex-column text-dark  main-menu">
            <li className="nav-item">
                <div className="col-12 p-2 text-center">
                    <img width="70%" src={this.props.logo} alt="Logo" />
                </div>
            </li>
            <li className="nav-item">
                <div onClick={e => e.stopPropagation()} className="col-12 p-2 search-box-activated">{search}</div>
            </li>
            <li className="nav-item">
                <Link to="/" className="nav-link text-dark">TRANG CHỦ</Link>
            </li>
            <li className="nav-item">
                <Link to="/s/gioi-thieu" className="nav-link text-dark">GIỚI THIỆU</Link>
            </li>
            <li className="nav-item">
                <Link to="/san-pham/" className="nav-link text-dark">SẢN PHẨM</Link>
            </li>
            <li className="nav-item">
                <Link to="/tin-tuc/" className="nav-link text-dark">TIN TỨC</Link>
            </li>
            <li className="nav-item">
                <Link to="/lich-su-bao-hanh" className="nav-link text-dark">LỊCH SỬ BẢO HÀNH</Link>
            </li>
            <li className="nav-item pl-3">
                <User color="dark" transform="uppercase" isMobile={true} />
            </li>
        </ul>;


        return (
            <div className="row">
                <div className="col-3 pt-1 pl-4">
                    <button className="btn btn-light" onClick={this.toggleDropdown}><i className="bi bi-list"></i></button>
                </div>
                <div className="logo col-5 text-center">
                    <a href="/"><img src={this.props.logo} alt="Logo" /></a>
                </div>
                <div className="col-4 text-right pt-1 pr-4 ">
                    <Cart />
                </div>
                <div onClick={this.toggleDropdown} className={'col-12 mobile-main-menu-wrapper' + (this.state.dropdown ? ' active' : '') }>{menu}<div className="background"></div></div>
            </div>
        );
    }
}