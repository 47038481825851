import User from "../models/user";
export interface State {
    user: User,
    token: string,
    loading: boolean,
    messages: string,
    loggedIn: boolean
}

export const initialState: State = {
    user: new User().fromJson({}),
    token: '',
    loading: false,
    messages: '',
    loggedIn: false
}

export const AuthReducer = (authState: State, action: { type: string, payload: any }): State => {
    switch (action.type) {
        case 'REQUEST_LOGIN':
            return {
                ...authState,
                messages: '',
                loading: true
            }
        case 'LOGIN_SUCCESS':
            return {
                ...authState,
                user: action.payload.user,
                token: action.payload.token,
                loading: false,
                loggedIn: true
            }
        case 'LOGIN_ERROR':
            return {
                ...authState,
                loading: false,
                messages: action.payload.messages
            }
        case 'CURRENT_CUSTOMER':
            return {
                ...authState,
                loading: false,
                user: action.payload.user,
                loggedIn: true
            }
        case 'LOGOUT':
            return {
                ...authState
            };
        default:
            return authState;
    }
}