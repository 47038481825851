import { Deserializable } from "./deserializable.interface";
import Model from "./model";

export default class Page extends Model implements Deserializable<Page> {
    p_id: number = 0;
    content: string = '';
    slug: string = '';
    title: string = '';

    fromJson(input: {}) : Page {
        super.fromJson(input);

        return this;
    }
}