import React from 'react';

export default class Info extends React.Component <any,any>{
    constructor(props: any) {
        super(props);
        this.state = {
          categories: [],
          brands: {},
        };
      }
    render() {
        const hotline = process.env.REACT_APP_HOTLINE;

        return (
            <div className="contact-info">
                <p className="mb-4"><strong>VỀ CHÚNG TÔI</strong></p>
            <p>{this?.props?.dataSetup?.brand_name}</p>
              <p>{this?.props?.dataSetup?.address}</p>
                <p>Hotline: {this?.props?.dataSetup?.contact_phone}</p>
            </div>
        );
    }
}
