import { Deserializable } from "./deserializable.interface";
import Model from "./model";

class RequestWarrantyModel extends Model implements Deserializable<RequestWarrantyModel> {
    id: number = 0;
    seri_number: string = "";
    created_at: string = "";
    product_name: string = "";
    status: string = "";
    address: string = "";
    content: string = "";
    name: string = "";
    phone: string = "";

    fromJson(input: any): RequestWarrantyModel {
        super.fromJson(input);
        return this;
    }
}

export default RequestWarrantyModel;