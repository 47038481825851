import React from 'react';
import { Link } from "react-router-dom";
import { getToken } from '../../actions/AuthAction';
import BrandsService from '../../services/brands';

export default class CustomerSupport extends React.Component <any,any> {
    constructor(props: any) {
        super(props);
        this.state = {
          categories: [],
          brands : {}
        };
    }
  async fetchBrands() {
    const response = await new BrandsService().getBrands()
    this.setState({
      brands : response?.items[0]
    })
    console.log(response?.items[0]);
  }
    componentDidMount() {
      this.fetchBrands();
    }
    render() {
        return (
            <div className="contact-info">
                <p className="mb-4"><strong>HỖ TRỢ KHÁCH HÀNG</strong></p>
                <p className="text-danger"><strong>CSKH và đặt hàng online: </strong></p>
                <p className="text-danger"><strong>{this?.state?.brands?.customer_care_phone}</strong></p>
            </div>
        );
    }
}