import React from 'react';

class Props {
    isLoading: boolean = false;
}

export default class Loading extends React.Component <Props, {}>{
    static defaultProps = {
        isLoading: false,
    };

    loading = <div className="w-100 text-primary text-center mt-4"><div className="spinner-border spinner-border-sm" role="status">
        <span className="sr-only">Loading...</span>
    </div></div>;

    render() 
    {
        return (
            <div>
                {this.props.isLoading 
                    ? this.loading 
                    : this.props.children}
            </div>
        );
    }
}