import React, { Suspense } from "react";
import { Route } from 'react-router-dom';
import { isValidToken } from "../actions/AuthAction";

interface Props {
    component: React.ComponentType<any>;
    routes: any
}

const PrivateRoute: React.FC<Props> = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props => (
                isValidToken()
                    ? <Suspense fallback={<></>}>
                        <Component {...props} />
                    </Suspense>
                    : window.location.href = "/dang-nhap"
            )}
        />
    );
}

export default PrivateRoute;
