import { Deserializable } from "./deserializable.interface";
import Model from "./model";
import Serial from "./serial";
import Category from "./category";
import Attribute from "./attribute";
import Combo from "./combo";

export default class Product extends Model implements Deserializable<Product> {
    p_id: number = 0;
    name!: string;
    sku!: string;
    quantity!: number;
    price: number = 0;
    price_for_ctv: number = 0;
    category!: Category[];
    serials: Serial[] = [];
    featured_image: string = "";
    desc: string = "";
    short_desc: string = "";
    product_gallery: string[] = [];
    stock_status!: boolean;
    attributes: Attribute[] = [];
    group_attribute_media: any[] = [];
    group_attribute_extra: any[] = [];
    required_quantity: number = 0;
    combos: Combo[] = [];
    width: number = 0;
    weight: number = 0;
    height: number = 0;
    length: number = 0;
    store_quantity: any[] = [];

    fromJson(input: any): Product {
        super.fromJson(input);
        if (typeof input.seri !== "undefined" && input.seri.length > 0) {
            this.serials = input.seri.map((seri: any) => {
                return new Serial().fromJson(seri);
            });
        }
        if (
            typeof input.group_attribute_extra !== "undefined" &&
            input.group_attribute_extra.length > 0
        ) {
            this.group_attribute_extra = input.group_attribute_extra.map(
                (group: any) => {
                    group["arr_attr_value_ids"] =
                        group["attr_value_ids"].split(",");

                    return group;
                }
            );
        }
        if (
            typeof input.attributes !== "undefined" &&
            input.attributes.length > 0
        ) {
            this.attributes = input.attributes.map((attribute: any) => {
                const attr = new Attribute().fromJson(attribute);
                attr.values = attr.values.map((attrValue: any) => {
                    return attrValue;
                });

                return attr;
            });
        }
        if (this.product_gallery.length == 0 && this.featured_image) {
            this.product_gallery = [this.featured_image];
        }
        if (typeof input.combos !== "undefined") {
            this.combos = input.combos
                .filter((combo: Combo) => combo.status === 1)
                .map((combo: Combo) => {
                    return new Combo().fromJson(combo);
                });
        }
        return this;
    }

    getMaxQuantity(selectedAttrs: any[]) {
        let quantity = this.quantity;

        if (selectedAttrs.length > 0) {
            const attrIds = selectedAttrs.join(",");
            const group = this.group_attribute_extra.filter(
                (e) => e.attr_value_ids == attrIds
            );
            quantity = group.length > 0 ? group[0].quantity : 0;
        }

        return quantity;
    }

    isInStock(selectedAttrs: any[]) {
        let stockStatus = this.stock_status;
        if (selectedAttrs.length > 0) {
            const attrIds = selectedAttrs.join(",");
            const quantity = this.group_attribute_extra.filter(
                (e) => e.attr_value_ids == attrIds
            );
            stockStatus = quantity.length > 0 && quantity[0].quantity > 0;
        }

        return stockStatus;
    }

    getPriceText() {
        return this.price ? this.price.toLocaleString() + " đ" : "Liên hệ";
    }

    getPriceForCTVText() {
        return this.price_for_ctv.toLocaleString() + " đ";
    }

    getStockStatus(selectedAttrs: any[]) {
        let stockStatus = this.isInStock(selectedAttrs);

        return stockStatus ? (
            <span className="text-success">Còn hàng</span>
        ) : (
            <span className="text-danger">Hết hàng</span>
        );
    }

    prepareForAddToCart(selectedAttrs: string[] = []) {
        let id: number[] = [this.p_id];
        let attrs: string[] = [];
        let attr_values: string[] = [];
        if (selectedAttrs.length > 0) {
            selectedAttrs.map((value: any, index: number) => {
                const attrValues = this.attributes[index].values.filter(
                    (attrValue) => attrValue.id == value
                );
                if (attrValues.length > 0) {
                    attrs.push(
                        this.attributes[index].text + ": " + attrValues[0].value
                    );
                }
                id.push(value);
                attr_values.push(value);
            });
        }

        return {
            id: id.join("_"),
            p_id: this.p_id,
            name: this.name,
            attrs: attrs,
            attr_ids: attr_values,
            price: this.price,
            price_for_ctv: this.price_for_ctv,
            featured_image: this.featured_image,
            weight: this.weight,
            length: this.length,
            width: this.width,
            height: this.height,
        };
    }

    getGaleryBySelectedAttributes(selectedAttrs: string[]) {
        let imgs = this.product_gallery;
        const attributeValues = selectedAttrs.join(",");
        const media = this.group_attribute_media.filter((gam) => {
            return gam.attr_value_ids == attributeValues;
        });
        if (media.length > 0) {
            const viewable = media[0].media;

            imgs = this.product_gallery.filter((img, index) => {
                return viewable.indexOf(index) !== -1;
            });
        }

        return imgs;
    }
}
