import React from 'react';
import Api from "./api";
import Product from "./../models/product";
import { exception } from 'console';
import { ProductPagination } from '../models/pagination';

export default class ProductService extends Api {
    protected prefix = 'products';

    getProductBySeri(serial: any): Promise<Product> {
        return this.get(this.prefix + '/product/' + serial);
    }

    activeWarrantyBySeri(serial: any, customerInfo: {}) {
        return this.post(this.prefix + '/seri/' + serial + '/active', customerInfo);
    }

    getProductBySku(sku: any, params: {} = {}): Promise<Product> {
        return this.get(this.prefix + '/' + sku, params);
    }

    getProducts(params: {}): Promise<ProductPagination> {
        return this.get(this.prefix, params);
    }

    getAvailableStores(token: any, params: {} = {}): Promise<[]> {
        return this.get(this.prefix + '/store-available', params, { 'Authorization': `Bearer ${token}` });
    }
}