import { Deserializable } from "./deserializable.interface";
import Model from "./model";

export default class User extends Model implements Deserializable<User> {
    id!: number;
    code!: string;
    name!: string;
    phone!: string;
    debt!: number;
    total_order!: number;
    total_sold_amount!: number;
    profit!: number;
    address!: string;
    province!: string;
    district!: string;
    ward!: string;
    group!: string;
    can_discount!: boolean;
    can_apply_voucher: boolean = true;
    balance: number = 0;
    locked_balance: number = 0;
    hasOwnedShippingService: boolean = false;
    available_shipping_setups: ShippingSetup[] = [];
    ableToPlaceAnOrder: boolean = true;
    can_create_sub: number = 0;
    created_at: string = '';
    az_point: number = 0;
    number_of_orders: number = 0;
    total_amount: number = 0;

    fromJson(input: any) {
        super.fromJson(input);
        if (typeof input.available_shipping_setups !== "undefined") {
            this.available_shipping_setups =
                input.available_shipping_setups.map((setup: ShippingSetup) => {
                    return new ShippingSetup().fromJson(setup);
                });
        }

        this.ableToPlaceAnOrder = !this.isKhachLe() || this.phone != '';
        if (typeof this.code == 'undefined' && typeof this.id != 'undefined') {
            this.code = 'DL-' + this.id;
        }
        
        return this;
    }

    isCTV() {
        return this.group === "ctv_dien_tu";
    }

    isKhachLe()
    {
        return this.group === "khách_hàng_Điện_tử";
    }

    getDebt() {
        return typeof this.debt !== "undefined"
            ? this.debt.toLocaleString() + " đ"
            : "0 đ";
    }

    getTotalSoldAmount() {
        return typeof this.total_sold_amount !== "undefined"
            ? this.total_sold_amount.toLocaleString() + " đ"
            : "0 đ";
    }

    getProfit() {
        return typeof this.profit !== "undefined"
            ? this.profit.toLocaleString() + " đ"
            : "0 đ";
    }

    isCtvWithDebt()
    {
        return this.hasOwnedShippingService;
    }
}

export class ShippingSetup
    extends Model
    implements Deserializable<ShippingSetup>
{
    name!: string;
    key!: string;

    fromJson(input: any): ShippingSetup {
        super.fromJson(input);

        return this;
    }
}
