import Api from './api';
export default class CategoryService extends Api {
    protected prefix = 'productcategories';

    getCategories = (token: string) => {
        return token ? super.get(this.prefix, {},
            {
                Authorization: `Bearer ${token}`,
            }
        ) : super.get(this.prefix);
    }
}