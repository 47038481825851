import React from 'react';
import "./topbar.scss";

interface TopbarProps {
    left: any;
    right: any;
}

export default class Topbar extends React.Component <TopbarProps, {}>{
    render() {
        return (
            <div className="top-bar">
                <div className="container">
                    <div className="row">
                        <div className="col-5 pr-0">{this.props.left}</div>
                        <div className="col-7 pl-0 text-right  text-white">{this.props.right}</div>
                    </div>
                </div>
            </div>
        );
    }
}